import React, { useState } from 'react'
import { getGlobalData } from '../utils'
import { useMount, useList } from 'react-use'

export const BGVideoContext = React.createContext()

const BGVideoProvider = ({ children }) => {
    const { global: globalData, words: wordsData } = getGlobalData()
    let videosArray = []
    videosArray.push(globalData.default_background_video.background_video)
    wordsData.forEach(word => {
        videosArray.push(word.background_video)
    });

    const [videos, {push: addVideo}] = useList(videosArray)
    const [activeVideo, setActiveVideo] = useState(videosArray[0])

    const setDefault = () => {
        setActiveVideo(videosArray[0])
    }

    return (
        <BGVideoContext.Provider value={{ activeVideo: activeVideo, setActiveVideo: setActiveVideo, videos: videos, setDefault: setDefault }}>
            {children}
        </BGVideoContext.Provider>
    )
}


export default BGVideoContext

export { BGVideoProvider }