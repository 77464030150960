import React from 'react'
import styled, { css } from 'styled-components'
import { GlobalStyles } from './src/styles/global'
import tw from 'tailwind.macro'
import Div100vh from 'react-div-100vh'

import { BGVideo } from './src/components'
import { BGVideoProvider } from './src/context/BGVideoContext'

export default ({ element, props }) => {
    return (
        <BGVideoProvider>
            <GlobalStyles />
            <Wrapper>
                <BGVideo />
                {element}
            </Wrapper>
        </BGVideoProvider>
    )
}

const Wrapper = styled(Div100vh)`
    ${tw`relative w-full`};

    #gatsby-focus-wrapper {
        ${tw`w-full h-full`};
    }
`