import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ReactPlayer from 'react-player'

import { media } from '../../styles/utils'
import { BGVideoContext } from '../../context/BGVideoContext'
import { motion } from 'framer-motion'
import { useMount, useList } from 'react-use'
import { forEach } from 'lodash'
import { bgImage } from '../../styles/global'

const BGVideo = props => {
    const videoContext = useContext(BGVideoContext)
    const [videosReady, setVideosReady] = useState(0)
    const [allVideosReady, setAllVideosReady] = useState(false)

    useEffect(() => {
        if (videosReady === videoContext.videos.length) {
            setAllVideosReady(true)
        }
    }, [videosReady])

    const onReady = () => {
        setVideosReady(videosReady+1)
    }

    const renderVideos = () => {
        return videoContext.videos.map((video, i) => {
            return (
                <Video
                    key={i}
                    as={ReactPlayer}
                    playing={allVideosReady ? true : false}
                    loop
                    muted
                    style={{opacity: videoContext.activeVideo === video ? 1 : 0}}
                    url={video}
                    onReady={onReady}
                />
            )
        })
    }

    return (
        <>
            {videoContext.videos && renderVideos()}
        </>
    )
}

const Video = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%!important;
    height: 100%!important;

    video {
        object-fit: cover;
        object-position: center;
    }

    /* animation */
    opacity: 0;
    transition: opacity 0.45s ease;
`

export default BGVideo
