import { css, createGlobalStyle } from 'styled-components'
import { media } from './utils'
import tw from 'tailwind.macro'

const maxWidth = 1680

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        ${tw`m-0 p-0 w-full h-full`};
        ${tw`font-body`};
        font-size: 20px;
        line-height: 1.3;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        color: white;

        ${media.mob`
            font-size: 16px;
        `}
    }

    .info-col {
        line-height: 1.47;
    }
    
    a {
        /* text-decoration: underline; */
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 1rem;
        font-weight: normal;
        margin: 0;
    }

    p:not(:first-child) {
        ${tw`mt-4`};
    }

    ul {
        list-style: disc;
        padding-left: 2rem;
    }
 
    * {
        -webkit-overflow-scrolling: touch;
    }
`

export const padding = css`
    ${tw`py-4 px-8`};
    box-sizing: border-box;
`

export const container = css`
    ${tw`w-full h-full mx-auto`};
    max-width: ${maxWidth}px;
    ${padding};
`

export const hoverState = css`
    opacity: 1;
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const button = css`
    ${tw`inline-block px-8 leading-none bg-transparent border border-white text-white rounded-full`};
    line-height: 1.4;
    padding-top: 0px;
    padding-bottom: 3px;
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const grid = css`
    ${tw`flex`};

    .col {
        ${tw`flex flex-col h-full p-2`};
        min-height: 100vh;
    }

    .col:first-child,
    .col:nth-child(2) {
        width: 45%;
    }

    .col:last-child {
        width: 10%;
        ${tw`flex flex-col items-end`};

        a {
            text-decoration: none;
        }
    }
`