import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import Transition from '../../transition'
import tw from 'tailwind.macro'

import { MetaTags } from '../'
import { media } from '../../styles/utils';
import { container } from '../../styles/global'

const Layout = ({ children, meta }) => {
	return (
		<>
			<MetaTags
				{...meta}
			/>
			<Wrapper>
				<Container>
					<Transition>
						{children}
					</Transition>
				</Container>
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div`
	${tw`relative w-full h-full`};
`

const Container = styled.div`
	${container};
`

export default Layout
